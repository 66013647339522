import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";

import { _BaseUtil } from "../utils/_BaseUtil";

import { ContractsApi } from "../api/ContractsApi";
import { EstimatesApi } from "../api/EstimatesApi";
import { TransactionsApi } from "../api/TransactionsApi";

import DocumentPreview from "../components/DocumentPreview";
import EstimateConfirmConditionItem, {
  getBadgeClass,
} from "../components/EstimateConfirmConditionItem";
import EstimateInvoiceTypeItem from "../components/EstimateInvoiceTypeItem";
import PaymentMethodModal from "../components/PaymentMethodModal";
import SelectProvince from "../components/Select/SelectProvince";
import LinksCard from "../components/Cards/LinksCard";

export default function EstimateConfirm() {
  const navigate = useNavigate();

  const { businessId, estimateId, linkId } = useParams();

  const [model, setModel] = useState({});
  const [conditions, setConditions] = useState([]);
  const [selectedConditionId, setSelectedConditionId] = useState(null);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [invoiceType, setInvoiceType] = useState(null);
  const [plans, setPlans] = useState([]);
  const [links, setLinks] = useState([]);
  const [providers, setProviders] = useState([]);

  const [customerIsPrivate, setCustomerIsPrivate] = useState(true);
  const [editAllowed, setEditAllowed] = useState(false);

  const [contractConditionsAccepted, setContractConditionsAccepted] =
    useState(false);

  const [canSubmit, setCanSubmit] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [formFieldsValid, setFormFieldsValid] = useState(null);

  const handleOnLoad = async () => {
    const result = await EstimatesApi.getPublicLinkAsync(
      businessId,
      estimateId,
      linkId
    );
    if (!result) return;
    setModel(result);
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    if (!model?.estimate) return;

    const { allowPublicPageEdit, customerType } = model.estimate;
    const isPrivate = customerType === 1;

    setCustomerIsPrivate(isPrivate);
    setEditAllowed(allowPublicPageEdit);

    let formFieldsValid =
      !!model.estimate.customerAddress &&
      !!model.estimate.customerCity &&
      !!model.estimate.customerZipCode &&
      !!model.estimate.customerStreetNumber &&
      !!model.estimate.customerFirstName &&
      !!model.estimate.customerLastName &&
      !!model.estimate.customerEmail;

    formFieldsValid =
      formFieldsValid &&
      (isPrivate
        ? !!model.estimate.customerGovernmentId
        : !!model.estimate.customerVatNumber &&
          (!!model.estimate.customerEInvoiceProviderCode ||
            !!model.estimate.customerCertifiedEmail));

    setFormFieldsValid(formFieldsValid);
  }, [model]);

  useEffect(() => {
    if (!model?.conditions?.length) return;
    setConditions(model.conditions);
  }, [model]);

  useEffect(() => {
    if (!model?.links?.length) return;
    setLinks(model.links);
  }, [model]);

  useEffect(() => {
    if (!model?.estimate) return;
    setContractConditionsAccepted(!model.estimate.requestContractAcceptance);
  }, [model]);

  useEffect(() => {
    if (!conditions.length) return;

    const defaultEstimateConditionId =
      conditions.filter((x) => x.isDefault)[0]?.rowKey ?? conditions[0]?.rowKey;

    setSelectedConditionId(defaultEstimateConditionId);
  }, [conditions]);

  useEffect(() => {
    if (model?.estimate) {
      form.setValues(model.estimate);

      const services = model.rows || [];

      // mapping plans as needed in modal
      let plans = services
        .filter((x) => x.serviceType === 2)
        .map((x) => {
          return {
            name: x.serviceName,
            startDate: x.startDate,
            totalAmount: x.totalAmountGross,
            currency: x.currency,
            intervals: x.billingIntervalsCount,
          };
        });
      setPlans(plans);
    }
  }, [model]);

  useEffect(() => {
    const condition = model?.conditions?.find(
      (x) => x.rowKey === selectedConditionId
    );
    setSelectedCondition(condition);
  }, [selectedConditionId]);

  useEffect(() => {
    setInvoiceType(null);

    let providers = [
      {
        code: 1, // Stripe
        method: 1, // Card
        name: "Stripe",
      },
      {
        code: 2, // ACube
        method: 1, // Transfer
        name: "Bonifico Bancario (paga con un link!)",
      },
    ];
    setProviders(providers);
  }, [selectedCondition]);

  useEffect(() => {
    if (!contractConditionsAccepted) {
      setCanSubmit(false);
      return;
    }

    if (
      !!conditions?.length &&
      (invoiceType === null || !selectedConditionId)
    ) {
      setCanSubmit(false);
      return;
    }

    setCanSubmit(true);
  }, [
    contractConditionsAccepted,
    conditions,
    invoiceType,
    selectedConditionId,
  ]);

  const handleDownloadContract = async () => {
    const result = await ContractsApi.getFor(
      businessId,
      "estimate",
      model.estimate.rowKey
    );

    if (result) {
      _BaseUtil.downloadPdf(result);
    }
  };

  const reviseAsync = async () => {
    const result = await EstimatesApi.revise(businessId, estimateId);
    if (result) navigate("/revision");
  };

  // il base prevede tutti i campi obbligatori tranne SDI e PEC, che gestisco separatamente a seconda della casistica
  const baseValidationSchema = {
    customerFirstName: Yup.string().required("Nome obbligatorio"),
    customerLastName: Yup.string().required("Cognome obbligatorio"),
    customerAddress: Yup.string().required("Indirizzo obbligatorio"),
    customerStreetNumber: Yup.string().required("Numero civico obbligatorio"),
    customerCity: Yup.string().required("Città obbligatoria"),
    customerZipCode: Yup.string().required("CAP obbligatorio"),
    customerProvince: Yup.string().required("Provincia obbligatoria"),
    customerEmail: Yup.string()
      .required("Email obbligatoria")
      .email("Email non valida"),
  };

  const getValidationSchema = () => {
    // se Business, PEC diventa obbligatoria in caso manchi lo SDI
    if (!customerIsPrivate) {
      return Yup.object({
        ...baseValidationSchema,
        customerVatNumber: Yup.string().required("P.IVA obbligatoria"),
        customerBusinessName: Yup.string().required(
          "Ragione Sociale obbligatoria"
        ),
        customerCertifiedEmail: Yup.string().when(
          "customerEInvoiceProviderCode",
          {
            is: (code) => !code || code.length === 0,
            then: () =>
              Yup.string()
                .required(
                  "E' obbligatorio inserire una PEC o il Codice Destinatario"
                )
                .email("PEC non valida"),
            otherwise: () => Yup.string().nullable(true), // Aggiunto per gestire il caso "otherwise"
          }
        ),
      });
    }

    // se Privato, PEC e SDI non ci sono, ma CF é obbligatorio
    return Yup.object({
      ...baseValidationSchema,
      customerGovernmentId: Yup.string().required(
        "Codice Fiscale obbligatorio"
      ),
    });
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerVatNumber: "",
      customerEInvoiceProviderCode: "",
      customerBusinessName: "",
      customerFirstName: "",
      customerLastName: "",
      customerCertifiedEmail: "",
      customerEmail: "",
      customerPhone: "",
      customerAddress: "",
      customerStreetNumber: "",
      customerCity: "",
      customerZipCode: "",
      customerProvince: "",
    },
    validationSchema: getValidationSchema(),
    onSubmit: () => handleSubmit,
  });

  const handleSubmit = async () => {
    try {
      const errors = await form.validateForm();

      if (Object.keys(errors).length) {
        console.log("form is not valid");
        return;
      }

      setShowModal(true);
    } catch {
      alert("C'è stato un errore durante il salvataggio");
    }
  };

  const handleConfirm = async function (provider, card) {
    let obj = model;
    obj.estimate = form.values;
    obj.estimate.paymentProvider = provider; //1. Stripe, 2. ACube
    obj.estimate.paymentMethod = provider === 1 ? 1 : 0; //1. Card, 2. Transfer
    obj.estimate.invoiceType = invoiceType || 0; //0. No Fattura
    obj.estimate.estimateConditionId = selectedConditionId;
    obj.estimate.eTag = undefined;
    obj.rows = obj.rows.map((items) => ({ ...items, eTag: undefined }));
    obj.links = obj.links.map((items) => ({ ...items, eTag: undefined }));
    obj.conditions = obj.conditions.map((items) => ({
      ...items,
      eTag: undefined, //always set eTag as undefined
    }));

    const { rowKey } = await EstimatesApi.acceptAsync(
      businessId,
      estimateId,
      linkId,
      obj
    );

    const result = await TransactionsApi.startAsync(
      businessId,
      rowKey, // transactionId
      {
        paymentProvider: provider, //1. Stripe, 2. ACube
        paymentMethod: provider === 1 ? 1 : 2, //1. Card, 2. Transfer
        description: "Pagamento Preventivo " + obj.estimate.code,
      }
    );

    return result;
  };

  return (
    !!model?.estimate && (
      <>
        <PaymentMethodModal
          amount={
            selectedCondition
              ? selectedCondition.amountWorkStartGross
              : model.estimate.totalAmountServicesGross
          }
          currency={
            selectedCondition
              ? selectedCondition.currency
              : model.estimate.currency
          }
          // cards={[fakeStripeCard]}
          providers={providers}
          plans={plans}
          isVisible={showModal}
          showConditions={true}
          onCancel={() => setShowModal(false)}
          onSubmit={handleConfirm}
        />
        <div className="auth-body-bg">
          <div className="container-fluid p-0">
            <Row className="g-0">
              <Col xxl={8}>
                <div className="auth-full-bg p-3">
                  <div className="w-100">
                    <div className="bg-overlay" />
                    <div className="d-flex h-100 flex-column">
                      <DocumentPreview model={model} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xxl={4}>
                <div className="auth-full-page-content p-3">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <Form onSubmit={form.handleSubmit}>
                        <div className="card">
                          <div className="card-body">
                            <fieldset>
                              <Row>
                                <Col xs={12}>
                                  <h4 className="card-title mb-4">
                                    Dati di fatturazione
                                  </h4>
                                  <Row>
                                    {customerIsPrivate ? (
                                      <>
                                        <Col xs={12}>
                                          <FormGroup>
                                            <Label for="customerGovernmentId">
                                              Codice Fiscale
                                            </Label>
                                            <Input
                                              id="customerGovernmentId"
                                              name="customerGovernmentId"
                                              type="text"
                                              className="mt-0"
                                              placeholder="Inserire il codice fiscale"
                                              disabled={
                                                !editAllowed &&
                                                model.estimate
                                                  .customerGovernmentId
                                              }
                                              value={
                                                form.values
                                                  .customerGovernmentId || ""
                                              }
                                              onBlur={form.handleBlur}
                                              invalid={
                                                form.touched
                                                  .customerGovernmentId &&
                                                form.errors.customerGovernmentId
                                              }
                                              onChange={form.handleChange}
                                            ></Input>
                                            {form.touched
                                              .customerGovernmentId &&
                                              form.errors
                                                .customerGovernmentId && (
                                                <FormFeedback type="invalid">
                                                  {
                                                    form.errors
                                                      .customerGovernmentId
                                                  }
                                                </FormFeedback>
                                              )}
                                          </FormGroup>
                                        </Col>
                                      </>
                                    ) : (
                                      <>
                                        <Col xs={12} sm={6}>
                                          <FormGroup>
                                            <Label for="customerVatNumber">
                                              P.IVA
                                            </Label>
                                            <Input
                                              id="customerVatNumber"
                                              name="customerVatNumber"
                                              type="text"
                                              className="mt-0"
                                              placeholder="Inserire P.IVA per la validazione automatica"
                                              disabled={
                                                !editAllowed &&
                                                model.estimate.customerVatNumber
                                              }
                                              value={
                                                form.values.customerVatNumber ||
                                                ""
                                              }
                                              onBlur={form.handleBlur}
                                              invalid={
                                                form.touched
                                                  .customerVatNumber &&
                                                form.errors.customerVatNumber
                                              }
                                              onChange={form.handleChange}
                                            ></Input>
                                            {form.touched.customerVatNumber &&
                                              form.errors.customerVatNumber && (
                                                <FormFeedback type="invalid">
                                                  {
                                                    form.errors
                                                      .customerVatNumber
                                                  }
                                                </FormFeedback>
                                              )}
                                          </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                          <FormGroup>
                                            <Label for="customerEInvoiceProviderCode">
                                              Codice Destinatario
                                            </Label>
                                            <Input
                                              id="customerEInvoiceProviderCode"
                                              name="customerEInvoiceProviderCode"
                                              type="text"
                                              className="mt-0"
                                              placeholder="Codice per la fatturazione elettronica"
                                              disabled={
                                                !editAllowed &&
                                                model.estimate
                                                  .customerEInvoiceProviderCode
                                              }
                                              value={
                                                form.values
                                                  .customerEInvoiceProviderCode ||
                                                ""
                                              }
                                              onBlur={form.handleBlur}
                                              invalid={
                                                form.touched
                                                  .customerEInvoiceProviderCode &&
                                                form.errors
                                                  .customerEInvoiceProviderCode
                                              }
                                              onChange={form.handleChange}
                                            ></Input>
                                            {form.touched
                                              .customerEInvoiceProviderCode &&
                                              form.errors
                                                .customerEInvoiceProviderCode && (
                                                <FormFeedback type="invalid">
                                                  {
                                                    form.errors
                                                      .customerEInvoiceProviderCode
                                                  }
                                                </FormFeedback>
                                              )}
                                          </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                          <FormGroup>
                                            <Label for="customerBusinessName">
                                              Ragione Sociale
                                            </Label>
                                            <Input
                                              id="customerBusinessName"
                                              name="customerBusinessName"
                                              type="text"
                                              className="mt-0"
                                              placeholder="Inserisci il nome dell'azienda"
                                              disabled={
                                                !editAllowed &&
                                                model.estimate
                                                  .customerBusinessName
                                              }
                                              value={
                                                form.values
                                                  .customerBusinessName || ""
                                              }
                                              onBlur={form.handleBlur}
                                              invalid={
                                                form.touched
                                                  .customerBusinessName &&
                                                form.errors.customerBusinessName
                                              }
                                              onChange={form.handleChange}
                                            ></Input>
                                            {form.touched
                                              .customerBusinessName &&
                                              form.errors
                                                .customerBusinessName && (
                                                <FormFeedback type="invalid">
                                                  {
                                                    form.errors
                                                      .customerBusinessName
                                                  }
                                                </FormFeedback>
                                              )}
                                          </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                          <FormGroup>
                                            <Label for="customerCertifiedEmail">
                                              PEC
                                            </Label>
                                            <Input
                                              id="customerCertifiedEmail"
                                              name="customerCertifiedEmail"
                                              type="text"
                                              className="mt-0"
                                              placeholder="PEC aziendale per ricezione contratto"
                                              disabled={
                                                !editAllowed &&
                                                model.estimate
                                                  .customerCertifiedEmail
                                              }
                                              value={
                                                form.values
                                                  .customerCertifiedEmail || ""
                                              }
                                              onBlur={form.handleBlur}
                                              invalid={
                                                form.touched
                                                  .customerCertifiedEmail &&
                                                form.errors
                                                  .customerCertifiedEmail
                                              }
                                              onChange={form.handleChange}
                                            ></Input>
                                            {form.touched
                                              .customerCertifiedEmail &&
                                              form.errors
                                                .customerCertifiedEmail && (
                                                <FormFeedback type="invalid">
                                                  {
                                                    form.errors
                                                      .customerCertifiedEmail
                                                  }
                                                </FormFeedback>
                                              )}
                                          </FormGroup>
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                  <Row>
                                    <Col xs={12} sm={6}>
                                      <FormGroup>
                                        <Label for="customerFirstName">
                                          Nome
                                        </Label>
                                        <Input
                                          id="customerFirstName"
                                          name="customerFirstName"
                                          type="text"
                                          className="mt-0"
                                          placeholder="Nome"
                                          disabled={
                                            !editAllowed &&
                                            model.estimate.customerFirstName
                                          }
                                          value={
                                            form.values.customerFirstName || ""
                                          }
                                          onBlur={form.handleBlur}
                                          invalid={
                                            form.touched.customerFirstName &&
                                            form.errors.customerFirstName
                                          }
                                          onChange={form.handleChange}
                                        ></Input>
                                        {form.touched.customerFirstName &&
                                          form.errors.customerFirstName && (
                                            <FormFeedback type="invalid">
                                              {form.errors.customerFirstName}
                                            </FormFeedback>
                                          )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                      <FormGroup>
                                        <Label for="customerLastName">
                                          Cognome
                                        </Label>
                                        <Input
                                          id="customerLastName"
                                          name="customerLastName"
                                          type="text"
                                          className="mt-0"
                                          placeholder="Cognome"
                                          disabled={
                                            !editAllowed &&
                                            model.estimate.customerLastName
                                          }
                                          value={
                                            form.values.customerLastName || ""
                                          }
                                          onBlur={form.handleBlur}
                                          invalid={
                                            form.touched.customerLastName &&
                                            form.errors.customerLastName
                                          }
                                          onChange={form.handleChange}
                                        ></Input>
                                        {form.touched.customerLastName &&
                                          form.errors.customerLastName && (
                                            <FormFeedback type="invalid">
                                              {form.errors.customerLastName}
                                            </FormFeedback>
                                          )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                      <FormGroup>
                                        <Label for="customerEmail">Email</Label>
                                        <Input
                                          id="customerEmail"
                                          name="customerEmail"
                                          type="text"
                                          className="mt-0"
                                          placeholder="Inserire e-mail"
                                          disabled={
                                            !editAllowed &&
                                            model.estimate.customerEmail
                                          }
                                          value={
                                            form.values.customerEmail || ""
                                          }
                                          onBlur={form.handleBlur}
                                          invalid={
                                            form.touched.customerEmail &&
                                            form.errors.customerEmail
                                          }
                                          onChange={form.handleChange}
                                        ></Input>
                                        {form.touched.customerEmail &&
                                          form.errors.customerEmail && (
                                            <FormFeedback type="invalid">
                                              {form.errors.customerEmail}
                                            </FormFeedback>
                                          )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                      <FormGroup>
                                        <Label for="customerPhone">
                                          Telefono
                                        </Label>
                                        <Input
                                          id="customerPhone"
                                          name="customerPhone"
                                          type="text"
                                          className="mt-0"
                                          placeholder="Inserire il numero di telefono"
                                          disabled={
                                            !editAllowed &&
                                            model.estimate.customerPhone
                                          }
                                          value={
                                            form.values.customerPhone || ""
                                          }
                                          onBlur={form.handleBlur}
                                          invalid={
                                            form.touched.customerPhone &&
                                            form.errors.customerPhone
                                          }
                                          onChange={form.handleChange}
                                        ></Input>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={12} sm={6}>
                                      <FormGroup>
                                        <Label for="customerAddress">
                                          Indirizzo
                                        </Label>
                                        <Input
                                          id="customerAddress"
                                          name="customerAddress"
                                          type="text"
                                          className="mt-0"
                                          placeholder={
                                            customerIsPrivate
                                              ? `Indirizzo`
                                              : `Indirizzo della sede legale`
                                          }
                                          disabled={
                                            !editAllowed &&
                                            model.estimate.customerAddress
                                          }
                                          value={
                                            form.values.customerAddress || ""
                                          }
                                          onBlur={form.handleBlur}
                                          invalid={
                                            form.touched.customerAddress &&
                                            form.errors.customerAddress
                                          }
                                          onChange={form.handleChange}
                                        ></Input>
                                        {form.touched.customerAddress &&
                                          form.errors.customerAddress && (
                                            <FormFeedback type="invalid">
                                              {form.errors.customerAddress}
                                            </FormFeedback>
                                          )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                      <FormGroup>
                                        <Label for="customerStreetNumber">
                                          Numero civico
                                        </Label>
                                        <Input
                                          id="customerStreetNumber"
                                          name="customerStreetNumber"
                                          type="text"
                                          className="mt-0"
                                          placeholder="Numero civico"
                                          disabled={
                                            !editAllowed &&
                                            model.estimate.customerStreetNumber
                                          }
                                          value={
                                            form.values.customerStreetNumber ||
                                            ""
                                          }
                                          onBlur={form.handleBlur}
                                          invalid={
                                            form.touched.customerStreetNumber &&
                                            form.errors.customerStreetNumber
                                          }
                                          onChange={form.handleChange}
                                        ></Input>
                                        {form.touched.customerStreetNumber &&
                                          form.errors.customerStreetNumber && (
                                            <FormFeedback type="invalid">
                                              {form.errors.customerStreetNumber}
                                            </FormFeedback>
                                          )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                      <FormGroup>
                                        <Label for="customerCity">Città</Label>
                                        <Input
                                          id="customerCity"
                                          name="customerCity"
                                          type="text"
                                          className="mt-0"
                                          placeholder="Città"
                                          disabled={
                                            !editAllowed &&
                                            model.estimate.customerCity
                                          }
                                          value={form.values.customerCity || ""}
                                          onBlur={form.handleBlur}
                                          invalid={
                                            form.touched.customerCity &&
                                            form.errors.customerCity
                                          }
                                          onChange={form.handleChange}
                                        ></Input>
                                        {form.touched.customerCity &&
                                          form.errors.customerCity && (
                                            <FormFeedback type="invalid">
                                              {form.errors.customerCity}
                                            </FormFeedback>
                                          )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                      <FormGroup>
                                        <label
                                          htmlFor="customerProvince"
                                          className="form-label"
                                        >
                                          Provincia
                                        </label>
                                        <SelectProvince
                                          id="customerProvince"
                                          name="customerProvince"
                                          value={
                                            form.values.customerProvince || ""
                                          }
                                          disabled={
                                            !editAllowed &&
                                            model.estimate.customerProvince
                                          }
                                          onChange={(value) =>
                                            form.setFieldValue(
                                              "customerProvince",
                                              value
                                            )
                                          }
                                          onBlur={() =>
                                            form.setFieldTouched(
                                              "customerProvince",
                                              true
                                            )
                                          }
                                          invalid={
                                            form.touched.customerProvince &&
                                            form.errors.customerProvince
                                          }
                                        />
                                        {form.touched.customerProvince &&
                                          form.errors.customerProvince && (
                                            <div
                                              style={{
                                                color: "red",
                                                marginTop: ".5rem",
                                              }}
                                            >
                                              {form.errors.customerProvince}
                                            </div>
                                          )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                      <FormGroup>
                                        <Label for="customerZipCode">CAP</Label>
                                        <Input
                                          id="customerZipCode"
                                          name="customerZipCode"
                                          type="text"
                                          className="mt-0"
                                          placeholder="CAP"
                                          disabled={
                                            !editAllowed &&
                                            model.estimate.customerZipCode
                                          }
                                          value={
                                            form.values.customerZipCode || ""
                                          }
                                          onBlur={form.handleBlur}
                                          invalid={
                                            form.touched.customerZipCode &&
                                            form.errors.customerZipCode
                                          }
                                          onChange={form.handleChange}
                                        ></Input>
                                        {form.touched.customerZipCode &&
                                          form.errors.customerZipCode && (
                                            <FormFeedback type="invalid">
                                              {form.errors.customerZipCode}
                                            </FormFeedback>
                                          )}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </fieldset>
                          </div>
                        </div>

                        {!!conditions?.length && (
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title mb-4">
                                Pagamento servizi una tantum
                              </h4>
                              {conditions.map((x, i) => {
                                return (
                                  <div className="my-2">
                                    <EstimateConfirmConditionItem
                                      index={i}
                                      condition={x}
                                      onClick={() =>
                                        setSelectedConditionId(x.rowKey)
                                      }
                                      checked={x.rowKey === selectedConditionId}
                                      badgeClass={getBadgeClass(
                                        x,
                                        Math.max(
                                          ...conditions.map(
                                            (condition) =>
                                              condition.discountPercentage || 0
                                          )
                                        )
                                      )}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}

                        {!!selectedCondition && (
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title my-4">
                                Tipo di fatturazione servizi una tantum
                              </h4>
                              {(selectedCondition.percentageWorkStart === 100 ||
                                selectedCondition.percentageWorkEnd ===
                                  100) && (
                                <div className="my-2">
                                  <EstimateInvoiceTypeItem
                                    index={1}
                                    invoiceType={1}
                                    checked={invoiceType === 1}
                                    onClick={() => setInvoiceType(1)}
                                  />
                                </div>
                              )}
                              {(selectedCondition.percentageWorkStart || 0) >
                                0 && (
                                <div className="my-2">
                                  <EstimateInvoiceTypeItem
                                    index={2}
                                    invoiceType={2}
                                    checked={invoiceType === 2}
                                    onClick={() => setInvoiceType(2)}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <LinksCard key={`links`} links={links} />

                        {!!model.estimate.requestContractAcceptance && (
                          <div className="card">
                            <div className="card-body">
                              <h4 className="card-title my-4">Contratto</h4>
                              <div className="my-2">
                                <div className="d-flex align-items-center form-check">
                                  <input
                                    id={`contractAcceptance`}
                                    name={`contractAcceptance`}
                                    className="form-check-input"
                                    type="radio"
                                    defaultChecked={contractConditionsAccepted}
                                    onChange={() =>
                                      setContractConditionsAccepted(
                                        !contractConditionsAccepted
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`contractAcceptance`}
                                    className="ms-2 form-check-label"
                                  >
                                    {`Accetta condizioni contrattuali`}
                                  </label>
                                </div>
                              </div>
                              <Link onClick={handleDownloadContract}>
                                <i className="bx bx-download"></i> Download
                              </Link>
                            </div>
                          </div>
                        )}

                        <div className="card">
                          <div className="card-body">
                            <Row>
                              <Col
                                xs={12}
                                className="d-flex justify-content-center"
                              >
                                <Button
                                  onClick={reviseAsync}
                                  color="primary"
                                  className="mx-2 w-75"
                                >
                                  <i className="bx bx-mail-send"></i>
                                  <span className="ms-2">
                                    Chiedi una revisione
                                  </span>
                                </Button>
                              </Col>
                              <Col
                                xs={12}
                                className="d-flex justify-content-center"
                              >
                                <Button
                                  type="submit"
                                  onClick={handleSubmit}
                                  color="success"
                                  className="mx-2 w-75"
                                  disabled={!canSubmit}
                                >
                                  <i className="bx bx-check-double"></i>
                                  <span className="ms-2">Conferma e paga</span>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-4">
                          <p className="mb-0">
                            © {new Date().getFullYear()} Design{" "}
                            <i className="mdi mdi-heart text-danger"></i> by
                            HoloTeam
                          </p>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    )
  );
}
